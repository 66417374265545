import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";
import Rarrow from "../../assets/image/svg/rarrow.svg";
import imgMobile from "../../assets/image/jpeg/hydra.jpg";
import Video from "../../components/vimeo.js";
import img1 from "../../assets/image/jpeg/hydrafacial1.jpg";
import img2 from "../../assets/image/jpeg/hydrafacial2.jpg";
import img3 from "../../assets/image/jpeg/hydrafacial3.jpg";


const Bookbutton = styled.button`
font-size: 16px;
padding: 12px 34px;
    background-color: #006b7b;
    display: block;
    color: #fff;
    border-radius: 32px;
    transition: .5s;
    border: none;
   

  @media screen and (max-width: 767px) {
    background-color: #006b7b;
    display: inline-block;
    padding: 12px 34px;
    font-size: 14px;
  }
`;

const Separator = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #ffffff;

  ::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #f7f7fb;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const SeparatorEnd = styled.div`
  position: relative;
  width: 100%;
  height: 10px;
  background-color: #f7f7fb;

  ::before, ::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
  }

  ::before {
    top: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }

  ::after {
    bottom: 0;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    transform: rotateY(180deg);
  }
`;

const Iwrap = styled.div`
  display: flex;
  margin-bottom: 1em;
  align-items: center;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
    justify-content: flex-start;
  }

  @media screen and (max-width: 991px) {
    margin-bottom: 8px;
    justify-content: center;
    text-align: center;
  }
`;

const Itext = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  color: #1a1a1a;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;

  span{
    color:#00aec7;
  }

  @media screen and (max-width: 991px) {
    font-size: 12px;
  }
`;



const Secondtext = styled.h2`
  font-size: 2.2em;
  margin-bottom: 20px;
  color: #1a1a1a;
  font-weight: 200;
  letter-spacing: 2px;

  @media screen and (max-width: 767px) {
    margin-bottom: 24px;
    font-size: 16px;
  
    margin: 0 auto;
  
  }
`;



const FeatureCard = ({ iconImage, title, children, ...rest }) => (
    <Box {...rest}>
      <Box className="d-flex align-items-center " mb={[0, 0, 0, 0]} pb={0}>
        <img src={iconImage} alt="" width="30px" className="mr-3" />
        <Text color="#000 " fontSize="14px" variant="medium">{children}</Text>
      </Box>
      <Box>
       
       
      </Box>
    </Box>
  );


const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`

  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 100%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    
  }
`;

const ConHydraPage = () => (
  <>
 
    {/* <!-- Content section 2 --> */}
    <Section py={4} bg="#ffffff">


        <Container className="pb-lg-5">
        <Row className="align-items-center">
            <Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6" className="mb-4 pt-lg-3">
        
            <Secondtext className="pb-4">
            Why choose HydraFacial at Dermamina?

      </Secondtext>
      <Text>
      At Dermamina, we like to go the extra mile by incorporating the multi award winning Dermalux LED Phototherapy to finish off the Platinum HydraFacial. Unlike any other standard HydraFacial, your HydraFacial will be boosted with one of the most powerful LED systems in the world, with clinically proven wavelengths. <br /> <br />  Dermalux LED Phototherapy instantly boosts the complexion to restore vitality and glow, through non-invasive therapeutic light energy that triggers the skins natural rejuvenation and repair processes – a perfect finish to achieving the best skin of your life. 
      </Text>
</Col>
<Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6">
      <Video
        videoSrcURL="https://www.youtube.com/embed/I2Hr9K2B7dU"
        videoTitle="HydraFacial Treatment London"
      /> 

     

      </Col> </Row>

   </Container>

      <Container className="pb-lg-5 pt-4">

      <Row  className="align-items-center">

      <Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6" className="mb-4 pt-lg-3 mb-lg-0 order-lg-2">
            <Secondtext className="pb-4">
            HydraFacial Benefits:
      </Secondtext>
      <FeatureCard title="Treatment Time" iconImage={Rarrow}>
      Effective and suitable for every skin types
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Results are instantly visible - a radiant and healthy-looking skin 
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         No downtime or discomfort – a refreshing and relaxing treatment 
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Powerful non-invasive, pain-free procedure 
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Instant improvement in skin texture, clarity, appearance of signs of ageing, as well as firming and tightening the skin
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Can be personalised to your skin concern with the addition of bespoke skin Boosters 
         </FeatureCard>

         </ Col >
         
         <Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6" className="order-lg-1">

         <Video
        videoSrcURL="https://player.vimeo.com/video/235902308?h=ea58d0fb73"
        videoTitle="Introducing HydraFacial"
      />
</Col>

</Row>

</Container>

<Container className="pb-lg-5">

<Row  className="align-items-center">

<Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6" className="mb-4 pt-lg-3 mb-lg-0">


            <Secondtext className="pb-4">
            HydraFacial Treats:
      </Secondtext>
      <Text>
      HydraFacial can be a treatment of choice for anyone and everyone to obtain and maintain healthy looking skin.  This 6 in 1 facial combines the pleasant experience of an ordinary facial with results that you would expect from a medical facial treatment, but without the pain and downtime. While it is an ideal treatment to maintain healthy and hydrated looking skin, it can also address different skin conditions such as:
      </Text> <br />

      <FeatureCard title="Treatment Time" iconImage={Rarrow}>
      Acne 
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Brown spots 
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Oily and congested skin
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Dull skin 
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Dry skin
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Pigmentation  
         </FeatureCard>
         <br /> <br />




  
         </Col>
       

         <Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6" >
         <ImgStyled src={img1} alt="" className="rounded shadow" /> <br /> <br />


         </Col>
         </Row>
         </Container>



         <Container className="pb-lg-5">

<Row  className="align-items-center">

<Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6" className="mb-4 pt-lg-3 mb-lg-0 order-lg-2">


      
            <Secondtext className="pb-4">
            How does HydraFacial London work?
      </Secondtext>

      <Text>
      HydraFacial combines 6 of the most effective proven different approaches, into one treatment process. The powerful and nourishing 6 stages at Dermamina includes: lymphatic drainage, exfoliation, GlySal™ Peel, extractions, infusion of antioxidants and bathing the skin in the award winning Dermalux LED light. These steps result in deeply cleansed and hydrated skin that looks and feels incredible.
      </Text> <br /> 

      <Text>
         HydraFacial delivers instant and long-lasting results that you can see and feel. With regular treatments, you will notice vast improvement in your skin, while addressing the different skin concerns. It is a very effective treatment to treat mild acne – our expert therapist will provide a tailored treatment for your type of acne. If you have dull and dry skin, this treatment will revive and refresh your skin, making it look smooth and hydrated. The treatment can also help in reducing dark spots and the appearance of fine lines and wrinkles. HydraFacial also involves an element of dermabrasion, which can help reduce the visibility of pigmentation.  
         </Text>

      <br /> 
      </Col>
      <Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6">
         <ImgStyled src={img2} alt="" className="rounded shadow" /> 

         </Col>
         </Row>
         </Container>
     

         <Container className="pb-lg-5 pt-4">

<Row  className="align-items-center">

<Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6" className="mb-4 pt-lg-3 mb-lg-0 ">

     
            <Secondtext className="pb-4">
            The Steps
      </Secondtext>

      <FeatureCard title="Treatment Time" iconImage={Rarrow}>
      Deep Cleanse
         </FeatureCard>
         <Text>Preparing and relaxing the skin <br /> <br /></Text>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Detox 
         </FeatureCard>
         <Text>Lymphatic Drainage is a unique massage technique that increases blood circulation, improving your complexion and radiance, while removing toxins<br /> <br /></Text>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Exfoliation 
         </FeatureCard>
         <Text> Gentle removal of dead skin , dirt and debris from the pores and skin<br /> <br /></Text>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         GlySal™ Peel  
         </FeatureCard>
         <Text> Glycolic peel with Salicylic buffer Chemical Peel deeply resurfaces layers of the skin stimulating your body to quickly replenish it with new , revealing a smoother and more radiant complexion<br /> <br /></Text>


         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Extraction  
         </FeatureCard>
         <Text> Eradicates blackheads and other impurities with pain-free vortex extraction<br /> <br /></Text>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Skin Boosters * 
         </FeatureCard>
         <Text>  Nourish and quench the skin with bespoke skin boosters to address specific skin concerns. HydraFacial super serums are tailored to your individual needs.<br /> <br /></Text>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Hydration  
         </FeatureCard>
         <Text> Replenish skins surface with a combination of hyaluronic acid, antioxidants and peptides to hydrate and maximise your glow <br /> <br /></Text>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Rejuvenation   
         </FeatureCard>
         <Text className="pt-4"> At Dermamina London we finish with Dermalux LED light to further stimulate collagen, supporting the natural production of vitamin D and reducing redness for an immediate glow</Text> 
</Col>

<Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6">

         <Video
        videoSrcURL="https://player.vimeo.com/video/235895500?h=4167dabbe8"
        videoTitle="How Does the HydraFacial Treatment Work?"
      /> 

      </Col>
</Row>
</Container>

<Container className="pb-lg-5">

<Row  className="align-items-center">

<Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6" className="mb-4 pt-lg-3 mb-lg-0 order-lg-2">

         <Text>*Personalise your treatment by adding our HydraFacial super serum Boosters to address specific skin concerns. HydraFacial booster serums at Dermamina London are produced in collaboration with the world’s famous cosmetic brands such as Zo Skin Health</Text> <br /> 

         <Text>
         Each treatment is tailored to your skin needs, to address specific skin concerns, add one or more HydraFacial super serum Booster, with the help of our expert skin therapist who can help you determine the right skin booster based on your skin condition and goals. specialised Boosters can target specific skin concerns, including: 
         </Text> <br />

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Brighten: Pigmented lesions and Melasma 
         </FeatureCard>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Restore & Firm: collagen and elastin stimulation 
         </FeatureCard>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Smooth & Refine: Fine lines and Wrinkles
         </FeatureCard>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Hydrate: Hydration and Repair 
         </FeatureCard>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Calm: Redness, Inflammation, sensitised 
         </FeatureCard>

         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Perk Eye: Hydration and brightening 
         </FeatureCard>
         <FeatureCard title="Treatment Time" iconImage={Rarrow}>
         Perk Lip: Hydration and Exfoliation 
         </FeatureCard> 
         </Col>
         
         <br /> <br />
     <Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false" lg="6">
         <ImgStyled src={img3} alt="" className="rounded shadow" />    
         </Col>
         </Row>
         </Container>
         

         <Container className="pb-lg-5 pt-4">

<Row  className="align-items-center">

<Col  data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-delay="200"
              data-aos-once="false">
         
         <Text>
         At Dermamina London, all skin treatments will begin with a thorough facial assessment and consultation so we can evaluate and understand your skin concerns and work out the best treatment for you. HydraFacial is a treatment of choice for anyone and everyone, even the most sensitive skin can tolerate our HydraFacial treatment.  <br /> <br />

         Dermamina also offers an option for anyone with a busy schedule wanting a quick (lunch time break facial treatment) refreshing HydraFacial to revive and hydrate the skin with our Signature 20-minute facial. For the full luxurious experience, you can try our Platinum range consisting of the advanced six steps to the best skin of our life. 
         </Text>








         
         
                 </Col> 
        </Row>
   </Container>
    </Section>
   
  </>
);

export default ConHydraPage;
